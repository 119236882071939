import { getPaginated } from '@/application/api/getPaginated';
import { get, put } from '@/application/api/implementations/app';
import { AxiosResponse } from 'axios';
import type { PaginatedRequest } from '@/application/api/getPaginated';
import type { TaskDefinition, TaskDefinitionIndex, UpdateRequest } from '@/modules/task/types';

export const index = (data: PaginatedRequest) =>  getPaginated<TaskDefinitionIndex[]>('task-definition', data);

export const show = (taskDefinitionId: number) => get<AxiosResponse<TaskDefinition>>(`task-definition/${taskDefinitionId}`);

export const update = (taskDefinition: UpdateRequest) => put(`task-definition/${taskDefinition.id}`, {
  ...taskDefinition,
  settings: taskDefinition.settings ?? undefined,
  roles: taskDefinition.roles?.map(role => role.id),
});
